import React, { useEffect, useState } from "react";
import api from "../api";
import {
  FaHourglassHalf,
  FaThLarge,
  FaUsers,
  FaUserFriends,
  FaMicrophone,
  FaUserCheck,
} from "react-icons/fa";

function InfoPage() {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchStats = () => {
      api
        .get("/stats")
        .then((res) => setStats(res.data))
        .catch((err) =>
          console.error(
            "Erreur lors de la récupération des statistiques :",
            err
          )
        );
    };
    fetchStats();

    const intervalId = setInterval(fetchStats, 60000);
    return () => clearInterval(intervalId);
  }, []);

  if (!stats) {
    return (
      <div className="text-center mt-4">
        <h6 className="text-lg">Chargement des statistiques...</h6>
      </div>
    );
  }

  const statItems = [
    {
      label: "Serveurs en attente",
      value: stats.waitlistServers.toLocaleString("fr-FR"),
      icon: <FaHourglassHalf className="text-yellow-500 h-16 w-16 mx-auto" />,
    },
    {
      label: "Serveurs actifs",
      value: stats.activeServers.toLocaleString("fr-FR"),
      icon: <FaThLarge className="text-green-500 h-16 w-16 mx-auto" />,
    },
    {
      label: "Membres totaux",
      value: stats.totalMembers.toLocaleString("fr-FR"),
      icon: <FaUsers className="text-blue-500 h-16 w-16 mx-auto" />,
    },
    {
      label: "Membres en ligne",
      value: stats.onlineMembers.toLocaleString("fr-FR"),
      icon: <FaUserFriends className="text-red-500 h-16 w-16 mx-auto" />,
    },
    {
      label: "Membres en vocal",
      value: stats.voiceMembers.toLocaleString("fr-FR"),
      icon: <FaMicrophone className="text-purple-500 h-16 w-16 mx-auto" />,
    },
    {
      label: "Utilisateurs enregistrés",
      value: stats.registeredUsers.toLocaleString("fr-FR"),
      icon: <FaUserCheck className="text-orange-500 h-16 w-16 mx-auto" />,
    },
  ];

  return (
    <div className="p-4">
      <h1 className="text-4xl font-bold mb-8 text-center">
        Statistiques de la plateforme
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {statItems.map((item, index) => (
          <div
            key={index}
            className="bg-gray-800 text-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-300 text-center"
          >
            {item.icon}
            <h2 className="text-xl font-semibold mt-4">{item.label}</h2>
            <p className="text-3xl mt-2">{item.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InfoPage;
