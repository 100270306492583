import React, { useEffect, useState } from "react";
import api from "../api";

// Fonction pour traduire le type de tri
const getSortTypeLabel = (sort) => {
  switch (sort) {
    case "averageRating":
      return "Note moyenne";
    case "voiceMemberCount":
      return "Membres en vocal";
    case "activeChatMembersCount":
      return "Membres actifs en chat";
    case "netChange":
      return "Variation nette";
    case "memberCount":
      return "Nombre de membres";
    default:
      return sort;
  }
};

const getActionLabel = (action) => {
  switch (action) {
    case "sort_servers":
      return "Tri des serveurs";
    case "add_server":
      return "Ajout d'un serveur";
    case "delete_server":
      return "Suppression d'un serveur";
    case "update_server":
      return "Mise à jour d'un serveur";
    case "add_review":
      return "Ajout d'un avis";
    case "delete_review":
      return "Suppression d'un avis";
    case "update_review":
      return "Mise à jour d'un avis";
    case "join_server":
      return "Rejoindre un serveur";
    case "get_servers":
      return "Consultation des serveurs";
    default:
      return action;
  }
};

function AdminLogsPage() {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    api
      .get("/logs")
      .then((res) => setLogs(res.data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-6">Logs d'activité</h1>
      <div className="overflow-x-auto">
        <table className="w-full table-auto bg-gray-800 text-white">
          <thead>
            <tr className="bg-gray-700">
              <th className="px-4 py-2">Date</th>
              <th className="px-4 py-2">Utilisateur</th>
              <th className="px-4 py-2">Action</th>
              <th className="px-4 py-2">Nom du serveur</th>
              <th className="px-4 py-2">ID du serveur</th>
              <th className="px-4 py-2">ID de l'avis</th>
              <th className="px-4 py-2">Note</th>
              <th className="px-4 py-2">Commentaire</th>
              <th className="px-4 py-2">Type de tri</th>
              <th className="px-4 py-2">Ordre</th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => {
              const details =
                typeof log.details === "string"
                  ? JSON.parse(log.details)
                  : log.details || {};
              return (
                <tr key={log.id} className="border-t border-gray-700">
                  <td className="px-4 py-2">
                    {new Date(log.timestamp).toLocaleString()}
                  </td>
                  <td className="px-4 py-2">
                    {log.username}#{log.discriminator}
                  </td>
                  <td className="px-4 py-2">{getActionLabel(log.action)}</td>
                  <td className="px-4 py-2">{details.serverName || ""}</td>
                  <td className="px-4 py-2">{details.serverId || ""}</td>
                  <td className="px-4 py-2">{details.reviewId || ""}</td>
                  <td className="px-4 py-2">{details.rating || ""}</td>
                  <td className="px-4 py-2">{details.comment || ""}</td>
                  <td className="px-4 py-2">
                    {log.action === "sort_servers"
                      ? getSortTypeLabel(details.sort) || ""
                      : ""}
                  </td>
                  <td className="px-4 py-2">
                    {log.action === "sort_servers" ? details.order || "" : ""}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminLogsPage;
