import React, { useEffect, useState } from "react";
import api from "../api";

function ModeratorsList() {
  const [moderators, setModerators] = useState([]);

  useEffect(() => {
    api
      .get("/moderators")
      .then((res) => setModerators(res.data))
      .catch((error) =>
        console.error("Erreur lors de la récupération des modérateurs :", error)
      );
  }, []);

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Liste des Modérateurs</h2>
      <ul className="space-y-2">
        {moderators.map((moderator) => (
          <li key={moderator.id} className="bg-gray-800 p-3 rounded">
            <p className="text-lg font-medium">
              {moderator.username}#{moderator.discriminator}
            </p>
            <p className="text-sm text-gray-400">ID : {moderator.id}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ModeratorsList; 