import React, { useState, useEffect } from "react";
import api from "../api";
import { useTranslation } from "react-i18next";

function Profile() {
  const { t } = useTranslation();
  const [user, setUser] = useState(null);

  useEffect(() => {
    api
      .get("/auth/user")
      .then((response) => {
        setUser(response.data);
      })
      .catch(() => {
        setUser(null);
      });
  }, []);

  if (!user) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-white">{t("loading")}</p>
      </div>
    );
  }

  // Construction de l'URL de l'avatar
  const avatarUrl = user.avatar
    ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
    : `https://cdn.discordapp.com/embed/avatars/${user.discriminator % 5}.png`;

  return (
    <div className="max-w-md mx-auto p-6 bg-gray-800 text-white rounded-lg mt-12 shadow-lg">
      <div className="flex flex-col items-center">
        <img
          src={avatarUrl}
          alt="Avatar"
          className="w-32 h-32 rounded-full mb-4 border-4 border-indigo-600"
        />
        <h1 className="text-3xl font-bold mb-2">{user.username}#{user.discriminator}</h1>
        <p className="text-indigo-400 mb-4">{t("profile")}</p>
        {/* Ajoutez d'autres informations utilisateur si nécessaire */}
      </div>
    </div>
  );
}

export default Profile;
