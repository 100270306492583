import React, { useState } from "react";
import { useParams } from "react-router-dom";
import api from "../api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { Helmet } from "react-helmet";
import ReviewForm from "./ReviewForm";
import "../App.css";
import {
  FaUsers,
  FaUserFriends,
  FaMicrophoneAlt,
  FaPenFancy,
  FaHeadset,
  FaArrowUp,
  FaArrowDown,
  FaEquals,
} from 'react-icons/fa';

function ServerDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const queryClient = useQueryClient();

  const categoryKeyMap = {
    Gaming: "category_gaming",
    Musique: "category_music",
    Technologie: "category_technology",
    Art: "category_art",
    "Anime/Manga": "category_anime",
    Roleplay: "category_roleplay",
    Communauté: "category_community",
    Éducation: "category_education",
    Sports: "category_sports",
    Memes: "category_memes",
    Programmation: "category_programming",
    Voyage: "category_travel",
    Fitness: "category_fitness",
    Cinéma: "category_movies",
    Livres: "category_books",
    Science: "category_science",
    Support: "category_support",
    NSFW: "category_nsfw",
    Streaming: "category_streaming",
    Crypto: "category_crypto",
    Cuisine: "category_cooking",
    Photographie: "category_photography",
    Esports: "category_esports",
    Animaux: "category_pets",
    "Développement Personnel": "category_personal_development",
    Mode: "category_fashion",
    Histoire: "category_history",
    Philosophie: "category_philosophy",
    Autre: "category_other",
  };

  const { data: currentUser } = useQuery(["currentUser"], () =>
    api
      .get("/auth/user")
      .then((res) => res.data)
      .catch(() => null)
  );

  const {
    data: server,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ["server", id],
    () => api.get(`/servers/${id}`).then((res) => res.data),
    {
      retry: false,
    }
  );

  const { refetch: refetchServers } = useQuery(["servers"], () =>
    api.get("/servers").then((res) => res.data.servers)
  );

  const handleReviewSubmitted = () => {
    queryClient.invalidateQueries(["server", id]);
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === "allServers",
    });
    queryClient.invalidateQueries(["servers"]);
    refetch();
  };

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const handleDeleteReview = async (reviewId) => {
    if (window.confirm(t("delete_review_confirmation"))) {
      try {
        await api.delete(`/reviews/${reviewId}`);
        refetch();
        refetchServers();
      } catch (err) {
        console.error("Erreur lors de la suppression de l'avis :", err);
      }
    }
  };

  const handleJoinServer = async () => {
    if (currentUser) {
      await api.post(`/servers/${server.id}/join`);
    }
    window.open(server.inviteLink, "_blank");
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center mt-4">
        <div className="loader"></div>
      </div>
    );
  }

  if (error) {
    return (
      <p className="text-center text-red-500 mt-4">
        {error.response && error.response.status === 401
          ? "Vous devez être connecté pour voir les détails de ce serveur."
          : "Erreur lors de la récupération du serveur."}
      </p>
    );
  }

  const imageUrl = server.imageUrl
    ? `https://cdn.discordapp.com/icons/${server.id}/${server.imageUrl}.png?size=300`
    : null;

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: server.name,
    url: `https://discordplace.com/servers/${server.id}`,
    logo: server.imageUrl,
    description: server.description,
  };

  return (
    <div className="server-details">
      <Helmet>
        {/* Meta tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaData)}
        </script>
      </Helmet>

      <img
        src={imageUrl || "/images/default-server-image.png"}
        alt={server.name}
        className="h-32 w-32 object-cover"
      />
      <h1>{server.name}</h1>
      <button
        className="button mt-4"
        onClick={handleJoinServer}
      >
        {t("join_server")}
      </button>

      {/* Affichage des catégories */}
      {server.categories && server.categories.length > 0 && (
        <div className="categories">
          {server.categories.map((category) => (
            <span key={category}>
              {t(categoryKeyMap[category] || category)}
            </span>
          ))}
        </div>
      )}

      {/* Affichage des tags */}
      {server.tags && server.tags.length > 0 && (
        <div className="tags">
          {server.tags.map((tag) => (
            <span key={tag}>
              #{tag}
            </span>
          ))}
        </div>
      )}

        <div className="mt-6">
          <div className="flex border-b border-gray-700">
            <button
              className={`py-2 px-4 ${
                tabValue === 0
                  ? "border-b-2 border-indigo-500 text-indigo-500"
                  : "text-gray-400"
              }`}
              onClick={() => handleTabChange(0)}
            >
              {t("information")}
            </button>
            <button
              className={`py-2 px-4 ${
                tabValue === 1
                  ? "border-b-2 border-indigo-500 text-indigo-500"
                  : "text-gray-400"
              }`}
              onClick={() => handleTabChange(1)}
            >
              {t("reviews")}
            </button>
          </div>
          <div className="mt-4">
            {tabValue === 0 && (
              <div>
                {server.description && (
                  <div className="mb-4">
                    <p className="text-lg">
                      {showFullDescription
                        ? server.description
                        : server.description.length > 300
                        ? `${server.description.slice(0, 300)}...`
                        : server.description}
                    </p>
                    {server.description.length > 300 && (
                      <button
                        onClick={() => setShowFullDescription(!showFullDescription)}
                        className="text-indigo-400 hover:text-indigo-300 mt-2 text-sm"
                      >
                        {showFullDescription ? t("show_less") : t("show_more")}
                      </button>
                    )}
                  </div>
                )}
                <div className="flex flex-wrap justify-center mt-6 space-x-6">
                  <div className="flex items-center space-x-2">
                    <FaUsers className="text-indigo-400" />
                    <p>{server.memberCount}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaUserFriends className="text-green-400" />
                    <p>{server.onlineMemberCount || 0}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaPenFancy className="text-yellow-400" />
                    <p>{server.active7d !== null ? `${server.active7d}` : "n/a"}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaMicrophoneAlt className="text-blue-400" />
                    <p>{server.voiceMemberCount || 0}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    {server.netChange > 0 ? (
                      <FaArrowUp className="text-green-400" />
                    ) : server.netChange < 0 ? (
                      <FaArrowDown className="text-red-400" />
                    ) : (
                      <FaEquals className="text-gray-400" />
                    )}
                    <p>{server.netChange}</p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <FaHeadset className="text-purple-400" />
                    <p>{server.activeVoiceChannelsCount || 0}</p>
                  </div>
                </div>
                <div className="flex items-center justify-center mt-4">
                  <div className="flex items-center">
                    <p className="text-2xl font-semibold">
                      {server.averageRating
                        ? `${Number(server.averageRating).toFixed(2)}/5`
                        : ""}
                    </p>
                    <span className="text-sm text-gray-400 ml-2">
                      ({server.reviewsCount || 0})
                    </span>
                  </div>
                </div>
              </div>
            )}
            {tabValue === 1 && (
              <div>
                {/* Formulaire pour ajouter un avis */}
                {currentUser && (
                  <ReviewForm
                    serverId={server.id}
                    onReviewSubmitted={handleReviewSubmitted}
                  />
                )}
                {/* Affichage des avis */}
                {server.reviews && server.reviews.length > 0 ? (
                  server.reviews.map((review) => (
                    <div
                      key={review.id}
                      className="bg-gray-700 p-4 rounded-lg mb-4"
                    >
                      <div className="flex items-center justify-between">
                        <div>
                          <p className="text-lg font-semibold">
                            {review.user.username}
                          </p>
                          <p className="text-sm text-gray-400">
                            {format(new Date(review.createdAt), "PPP", {
                              locale: fr,
                            })}
                          </p>
                        </div>
                        <div className="flex items-center">
                          <p className="text-xl font-semibold">
                            {review.rating}/5
                          </p>
                          {currentUser && currentUser.id === review.user.id && (
                            <button
                              className="text-red-500 ml-4"
                              onClick={() => handleDeleteReview(review.id)}
                            >
                              {t("delete")}
                            </button>
                          )}
                        </div>
                      </div>
                      <p className="mt-2">{review.comment}</p>
                    </div>
                  ))
                ) : (
                  <p className="text-center text-gray-400">
                    {t("no_reviews")}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
  );
}

export default ServerDetails;
