import React from "react";
import logo from "../images/discord-logo-white.png";
function Login() {
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
  const REDIRECT_URI = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
  const RESPONSE_TYPE = "code";
  const SCOPE = "identify email guilds";

  const loginUrl = `https://discord.com/oauth2/authorize?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${encodeURIComponent(
    SCOPE
  )}`;

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-900 text-white">
      <h1 className="text-4xl font-bold mb-4">Bienvenue sur DiscordPlace</h1>
      <p className="text-lg mb-6">
        Veuillez vous connecter avec Discord pour accéder au site.
      </p>
      <a
        href={loginUrl}
        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded flex items-center"
      >
        <img
          src={logo}
          alt="Discord Logo"
          className="w-6 h-6 mr-2"
        />
        Se connecter avec Discord
      </a>
    </div>
  );
}

export default Login;
