import React from "react";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import ServerCard from "./ServerCard";
import { useTranslation } from "react-i18next";

function ServerList({ isAdmin }) {
  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useQuery(["allServers"], () =>
    api.get("/admin/servers").then((res) => res.data.servers)
  );

  const handleDelete = async (serverId) => {
    if (window.confirm(t("confirm_delete_server"))) {
      try {
        await api.delete(`/admin/servers/${serverId}`);
        alert(t("server_deleted_successfully"));
        refetch();
      } catch (error) {
        console.error(t("error_deleting_server"), error);
        alert(t("error_deleting_server"));
      }
    }
  };

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-white">{t("loading")}</p>
      </div>
    );

  if (error)
    return (
      <p className="text-center text-red-500 mt-4">
        {t("error_loading_servers")}
      </p>
    );

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {data.map((server) => (
        <ServerCard
          key={server.id}
          server={server}
          handleDelete={handleDelete}
          isAdmin={isAdmin}
        />
      ))}
    </div>
  );
}

export default ServerList;
