import React, { useEffect, useState } from "react";
import api from "../api";
import ModeratorList from "./ModeratorsList.js";

function ModeratorPanel() {
  const [waitlistServers, setWaitlistServers] = useState([]);

  useEffect(() => {
    fetchWaitlistServers();
  }, []);

  const fetchWaitlistServers = async () => {
    try {
      const response = await api.get("/waitlist/servers");
      setWaitlistServers(response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des serveurs en attente :",
        error
      );
    }
  };

  const handleApprove = async (serverId) => {
    try {
      await api.post(`/waitlist/servers/${serverId}/approve`);
      setWaitlistServers(
        waitlistServers.filter((server) => server.id !== serverId)
      );
    } catch (error) {
      console.error("Erreur lors de l'approbation du serveur :", error);
    }
  };

  const handleReject = async (serverId) => {
    try {
      await api.post(`/waitlist/servers/${serverId}/reject`);
      setWaitlistServers(
        waitlistServers.filter((server) => server.id !== serverId)
      );
    } catch (error) {
      console.error("Erreur lors du rejet du serveur :", error);
    }
  };

  return (
    <div className="p-4">
      <ModeratorList />

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        Serveurs en attente d'approbation
      </h2>
      <ul className="space-y-4">
        {waitlistServers.map((server) => (
          <li key={server.id} className="bg-gray-800 p-4 rounded">
            <h3 className="text-xl font-bold mb-2">{server.name}</h3>
            <p className="text-sm">
              <strong>Propriétaire :</strong>{" "}
              {server.owner
                ? `${server.owner.username}#${server.owner.discriminator}`
                : "Propriétaire inconnu"}
            </p>
            <p className="text-sm">
              <strong>Soumis le :</strong>{" "}
              {server.createdAt
                ? new Date(server.createdAt).toLocaleString()
                : "Date inconnue"}
            </p>
            <p className="text-sm">
              <strong>Description :</strong>{" "}
              {server.description || "Aucune description"}
            </p>
            <p className="text-sm">
              <strong>Lien d'invitation :</strong>{" "}
              {server.inviteLink ? (
                <a
                  href={server.inviteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {server.inviteLink}
                </a>
              ) : (
                "Aucun lien disponible"
              )}
            </p>
            <div className="mt-4 flex space-x-2">
              <button
                onClick={() => handleApprove(server.id)}
                className="bg-green-600 hover:bg-green-700 text-white py-1 px-3 rounded"
              >
                Approuver
              </button>
              <button
                onClick={() => handleReject(server.id)}
                className="bg-red-600 hover:bg-red-700 text-white py-1 px-3 rounded"
              >
                Rejeter
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ModeratorPanel;
