import React, { useState } from "react";
import api from "../api";
import { useTranslation } from "react-i18next";

function ReviewForm({ serverId, onReviewSubmitted }) {
  const { t } = useTranslation();
  const [rating, setRating] = useState(2.5);
  const [comment, setComment] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      await api.post(`/servers/${serverId}/reviews`, {
        rating,
        comment,
      });
      setSuccess(t("review_submitted"));
      setRating(2.5);
      setComment("");
      onReviewSubmitted();
    } catch (err) {
      setError(
        err.response?.data?.message || t("review_submission_error")
      );
    }
  };

  return (
    <div className="mt-4">
      <h2 className="text-xl font-semibold">{t("add_review")}</h2>
      <form onSubmit={handleSubmit} className="mt-2">
        <div className="flex items-center">
          <label className="mr-2">{t("rating")} :</label>
          <input
            type="number"
            min="0"
            max="5"
            step="0.5"
            value={rating}
            onChange={(e) => setRating(parseFloat(e.target.value))}
            className="w-16 bg-gray-700 text-white p-1 rounded"
          />
        </div>
        <div className="mt-2">
          <label className="block mb-1">{t("comment")} :</label>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows="4"
            className="w-full bg-gray-700 text-white p-2 rounded"
          ></textarea>
        </div>
        {error && (
          <p className="text-red-500 mt-2">{error}</p>
        )}
        {success && (
          <p className="text-green-500 mt-2">{success}</p>
        )}
        <button
          type="submit"
          className="bg-indigo-600 text-white py-1 px-3 rounded hover:bg-indigo-500 mt-2"
        >
          {t("submit")}
        </button>
      </form>
    </div>
  );
}

export default ReviewForm;
