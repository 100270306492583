import React from 'react';

function Tooltip({ children, text }) {
  return (
    <div className="relative flex items-center group">
      {children}
      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 hidden group-hover:flex flex-col items-center">
        <div className="bg-gray-800 text-white text-xs rounded py-1 px-2 whitespace-no-wrap">
          {text}
        </div>
        <div className="w-2 h-2 bg-gray-800 rotate-45 transform mt-1"></div>
      </div>
    </div>
  );
}

export default Tooltip;
