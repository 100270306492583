import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Politique de Confidentialité</h1>

      <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
      <p className="mb-4">
        Votre vie privée est importante pour nous. Cette Politique de
        Confidentialité explique quelles informations nous collectons, comment
        nous les utilisons et les mesures que nous prenons pour les protéger.
      </p>

      <h2 className="text-2xl font-semibold mb-2">2. Informations que Nous Collectons</h2>
      <p className="mb-4">
        <strong>Informations Personnelles :</strong> Par exemple, votre nom
        d'utilisateur Discord, identifiant utilisateur, etc.
      </p>
      <p className="mb-4">
        <strong>Données d'Utilisation :</strong> Informations sur la façon dont
        vous interagissez avec l'Application.
      </p>

      <h2 className="text-2xl font-semibold mb-2">3. Comment Nous Utilisons Vos Informations</h2>
      <p className="mb-4">
        <strong>Fourniture de Services :</strong> Pour opérer et maintenir
        l'Application.
      </p>
      <p className="mb-4">
        <strong>Amélioration du Service :</strong> Pour comprendre et analyser les
        tendances et les préférences des utilisateurs.
      </p>

      <h2 className="text-2xl font-semibold mb-2">4. Partage de Vos Informations</h2>
      <p className="mb-4">
        Nous ne vendons ni ne louons vos informations personnelles à des tiers.
        Nous pouvons divulguer vos informations si la loi l'exige ou pour
        protéger nos droits.
      </p>

      <h2 className="text-2xl font-semibold mb-2">5. Sécurité des Données</h2>
      <p className="mb-4">
        Nous mettons en œuvre des mesures de sécurité pour protéger vos
        informations contre l'accès non autorisé.
      </p>

      <h2 className="text-2xl font-semibold mb-2">6. Cookies et Technologies Similaires</h2>
      <p className="mb-4">
        L'Application peut utiliser des cookies pour améliorer l'expérience
        utilisateur.
      </p>

      <h2 className="text-2xl font-semibold mb-2">7. Droits des Utilisateurs</h2>
      <p className="mb-4">
        Vous avez le droit d'accéder, de corriger ou de supprimer vos informations
        personnelles que nous détenons.
      </p>

      <h2 className="text-2xl font-semibold mb-2">8. Modifications de cette Politique</h2>
      <p className="mb-4">
        Nous pouvons mettre à jour cette Politique de Confidentialité de temps à
        autre. Les modifications seront notifiées via l'Application ou par tout
        autre moyen approprié.
      </p>

      <h2 className="text-2xl font-semibold mb-2">9. Contact</h2>
      <p>
        Si vous avez des questions concernant cette Politique de Confidentialité,
        veuillez nous contacter à :{' '}
        <a href="mailto:paulblanchaard@gmail.com" className="text-blue-500 underline">
          paulblanchaard@gmail.com
        </a>.
      </p>
    </div>
  );
}

export default PrivacyPolicy; 