import React, { useState } from "react";
import api from "../api";

function ModeratorsManagement() {
  const [discordId, setDiscordId] = useState("");
  const [message, setMessage] = useState("");

  const handleAddModerator = async () => {
    try {
      await api.post(`/users/${discordId}/setModerator`, {
        isModerator: true,
      });
      setMessage("Utilisateur défini comme modérateur avec succès");
      setDiscordId("");
    } catch (error) {
      console.error("Erreur lors de l'ajout du modérateur :", error);
      setMessage("Erreur lors de l'ajout du modérateur");
    }
  };

  const handleRemoveModerator = async () => {
    try {
      await api.post(`/users/${discordId}/setModerator`, {
        isModerator: false,
      });
      setMessage("Modérateur supprimé avec succès");
      setDiscordId("");
    } catch (error) {
      console.error("Erreur lors de la suppression du modérateur :", error);
      setMessage("Erreur lors de la suppression du modérateur");
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-semibold mb-4">Gestion des modérateurs</h2>
      <div className="flex items-center space-x-4 mb-4">
        <input
          type="text"
          placeholder="ID Discord de l'utilisateur"
          value={discordId}
          onChange={(e) => setDiscordId(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleAddModerator}
          className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded"
        >
          Ajouter comme modérateur
        </button>
        <button
          onClick={handleRemoveModerator}
          className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded"
        >
          Supprimer le modérateur
        </button>
      </div>
      {message && (
        <div
          className={`p-4 mb-4 rounded ${
            message.startsWith("Erreur")
              ? "bg-red-100 text-red-800"
              : "bg-green-100 text-green-800"
          }`}
        >
          {message}
          <button
            onClick={() => setMessage("")}
            className="float-right text-xl font-bold leading-none"
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
}

export default ModeratorsManagement;
