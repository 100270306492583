import React, { useState, useEffect } from 'react';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import ServerCard from '../components/ServerCard';
import BigInt from 'big-integer';
import { useQueryClient } from '@tanstack/react-query';

function Dashboard() {
  const [user, setUser] = useState(null);
  const [userServers, setUserServers] = useState([]);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // Récupérer les informations de l'utilisateur
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await api.get('/auth/user');
        setUser(res.data);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'utilisateur :", error);
        navigate('/login');
      }
    };

    fetchUser();
  }, [navigate]);

  // Récupérer les serveurs de l'utilisateur
  useEffect(() => {
    const fetchUserServers = async () => {
      try {
        const res = await api.get('/servers/user');
        setUserServers(res.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des serveurs de l'utilisateur :", error);
      }
    };

    fetchUserServers();
  }, []);

  // Fonction pour gérer le clic sur le bouton "Ajouter un Serveur"
  const handleAddServer = () => {
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    const REDIRECT_URI = encodeURIComponent('https://discordplace.com/api/servers/add');
    const RESPONSE_TYPE = 'code';
    const SCOPE = encodeURIComponent('bot applications.commands');
    const permissionsInt = BigInt(1 << 0); // Définissez les permissions nécessaires
    const permissions = permissionsInt.toString();

    const inviteURL = `https://discord.com/oauth2/authorize?client_id=${CLIENT_ID}&permissions=${permissions}&scope=${SCOPE}&response_type=${RESPONSE_TYPE}&redirect_uri=${REDIRECT_URI}`;

    window.location.href = inviteURL;
  };

  const handleUpdate = (serverId, updatedData) => {
    api
      .put(`/servers/${serverId}`, updatedData)
      .then((res) => {
        setMessage('Serveur mis à jour avec succès');
        // Mettre à jour la liste des serveurs avec les données renvoyées
        setUserServers((prevServers) =>
          prevServers.map((server) =>
            server.id === serverId ? res.data : server
          )
        );
        // Invalider la requête pour refetcher les serveurs dans Home.js
        queryClient.invalidateQueries(["servers"]);
      })
      .catch((error) => {
        console.error('Erreur lors de la mise à jour du serveur :', error);
        setMessage('Erreur lors de la mise à jour du serveur');
      });
  };

  if (!user) {
    return <p>Chargement...</p>;
  }

  return (
    <div className="max-w-6xl mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">
        Bienvenue, {user.username}#{user.discriminator}
      </h1>

      {message && (
        <div className="mb-4 p-4 rounded bg-green-100 text-green-800">
          {message}
          <button
            onClick={() => setMessage('')}
            className="float-right text-xl font-bold leading-none"
          >
            &times;
          </button>
        </div>
      )}

      {/* Bouton pour ajouter un serveur */}
      <div className="flex justify-end mb-4">
        <button
          onClick={handleAddServer}
          className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700"
        >
          Ajouter un Serveur
        </button>
      </div>

      {/* Affichage des serveurs de l'utilisateur */}
      <h2 className="text-xl font-semibold mb-2">Vos serveurs</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {userServers.map((server) => (
          <ServerCard
            key={server.id}
            server={server}
            handleUpdate={handleUpdate}
            isDashboard={true}
            userPermissions={{
              [server.id]: { canEdit: true },
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default Dashboard;