import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import api from "../api";
import ServerCard from "./ServerCard";
import LoadingSpinner from "./LoadingSpinner";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useQuery, useQueryClient } from "@tanstack/react-query";
import '../App.css';
import { FaHeart, FaRegHeart, FaGlobe } from "react-icons/fa";

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [servers, setServers] = useState([]);
  const [displayedServers, setDisplayedServers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState("averageRating");
  const [sortOrder, setSortOrder] = useState("desc");
  const [visibleServersCount, setVisibleServersCount] = useState(8);
  const listInnerRef = useRef();
  const [isFetching, setIsFetching] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [favoriteServers, setFavoriteServers] = useState([]);
  const [favoritesFilter, setFavoritesFilter] = useState(false);
  const queryClient = useQueryClient();
  const [selectedCategories, setSelectedCategories] = useState([]);

  const { data: fetchedServers = [], isFetching:isLoadingServers  } = useQuery(
    ["servers"],
    () => api.get("/servers").then((res) => res.data)
  );

  const { data: currentUser } = useQuery(
    ["currentUser"],
    () =>
      api
        .get("/auth/user")
        .then((res) => res.data)
        .catch(() => null),
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  
  const { data: fetchedFavorites = [], refetch: refetchFavorites } = useQuery(
    ["favorites"],
    () => api.get("/favorites").then((res) => res.data),
    {
      enabled: !!currentUser,
    }
  );

  useEffect(() => {
    setServers(fetchedServers || []);
    setFavoriteServers(fetchedFavorites || []);
  }, [fetchedServers, fetchedFavorites]);
  
  // Liste des pays pour le filtre
  const countryOptions = [
    { value: "AF", label: "Afghanistan" },
    { value: "ZA", label: "Afrique du Sud" },
    { value: "AL", label: "Albanie" },
    { value: "DZ", label: "Algérie" },
    { value: "DE", label: "Allemagne" },
    { value: "AD", label: "Andorre" },
    { value: "AO", label: "Angola" },
    { value: "AG", label: "Antigua-et-Barbuda" },
    { value: "SA", label: "Arabie Saoudite" },
    { value: "AR", label: "Argentine" },
    { value: "AM", label: "Arménie" },
    { value: "AU", label: "Australie" },
    { value: "AT", label: "Autriche" },
    { value: "AZ", label: "Azerbaïdjan" },
    { value: "BS", label: "Bahamas" },
    { value: "BH", label: "Bahreïn" },
    { value: "BD", label: "Bangladesh" },
    { value: "BB", label: "Barbade" },
    { value: "BY", label: "Biélorussie" },
    { value: "BE", label: "Belgique" },
    { value: "BZ", label: "Belize" },
    { value: "BJ", label: "Bénin" },
    { value: "BT", label: "Bhoutan" },
    { value: "BO", label: "Bolivie" },
    { value: "BA", label: "Bosnie-Herzégovine" },
    { value: "BW", label: "Botswana" },
    { value: "BR", label: "Brésil" },
    { value: "BN", label: "Brunei" },
    { value: "BG", label: "Bulgarie" },
    { value: "BF", label: "Burkina Faso" },
    { value: "BI", label: "Burundi" },
    { value: "KH", label: "Cambodge" },
    { value: "CM", label: "Cameroun" },
    { value: "CA", label: "Canada" },
    { value: "CV", label: "Cap-Vert" },
    { value: "CL", label: "Chili" },
    { value: "CN", label: "Chine" },
    { value: "CY", label: "Chypre" },
    { value: "CO", label: "Colombie" },
    { value: "KM", label: "Comores" },
    { value: "CG", label: "Congo-Brazzaville" },
    { value: "CD", label: "Congo-Kinshasa" },
    { value: "CR", label: "Costa Rica" },
    { value: "CI", label: "Côte d'Ivoire" },
    { value: "HR", label: "Croatie" },
    { value: "CU", label: "Cuba" },
    { value: "DK", label: "Danemark" },
    { value: "DJ", label: "Djibouti" },
    { value: "DM", label: "Dominique" },
    { value: "EG", label: "Égypte" },
    { value: "AE", label: "Émirats Arabes Unis" },
    { value: "EC", label: "Équateur" },
    { value: "ES", label: "Espagne" },
    { value: "EE", label: "Estonie" },
    { value: "US", label: "États-Unis" },
    { value: "FJ", label: "Fidji" },
    { value: "FI", label: "Finlande" },
    { value: "FR", label: "France" },
    { value: "GA", label: "Gabon" },
    { value: "GM", label: "Gambie" },
    { value: "GE", label: "Géorgie" },
    { value: "GH", label: "Ghana" },
    { value: "GR", label: "Grèce" },
    { value: "GD", label: "Grenade" },
    { value: "GP", label: "Guadeloupe" },
    { value: "GT", label: "Guatemala" },
    { value: "GN", label: "Guinée" },
    { value: "GW", label: "Guinée-Bissau" },
    { value: "GY", label: "Guyana" },
    { value: "GF", label: "Guyane française" },
    { value: "HT", label: "Haïti" },
    { value: "HN", label: "Honduras" },
    { value: "HU", label: "Hongrie" },
    { value: "HK", label: "Hong Kong" },
    { value: "IN", label: "Inde" },
    { value: "ID", label: "Indonésie" },
    { value: "IE", label: "Irlande" },
    { value: "IS", label: "Islande" },
    { value: "IL", label: "Israël" },
    { value: "IT", label: "Italie" },
    { value: "JM", label: "Jamaïque" },
    { value: "JP", label: "Japon" },
    { value: "JO", label: "Jordanie" },
    { value: "KZ", label: "Kazakhstan" },
    { value: "KE", label: "Kenya" },
    { value: "RE", label: "La Réunion" },
    { value: "LV", label: "Lettonie" },
    { value: "LS", label: "Lesotho" },
    { value: "LB", label: "Liban" },
    { value: "LR", label: "Libéria" },
    { value: "LY", label: "Libye" },
    { value: "LT", label: "Lituanie" },
    { value: "LU", label: "Luxembourg" },
    { value: "MO", label: "Macao" },
    { value: "MK", label: "Macédoine" },
    { value: "MG", label: "Madagascar" },
    { value: "MY", label: "Malaisie" },
    { value: "MW", label: "Malawi" },
    { value: "MV", label: "Maldives" },
    { value: "ML", label: "Mali" },
    { value: "MT", label: "Malte" },
    { value: "MA", label: "Maroc" },
    { value: "MQ", label: "Martinique" },
    { value: "YT", label: "Mayotte" },
    { value: "MX", label: "Mexique" },
    { value: "MD", label: "Moldavie" },
    { value: "MC", label: "Monaco" },
    { value: "MN", label: "Mongolie" },
    { value: "ME", label: "Monténégro" },
    { value: "MZ", label: "Mozambique" },
    { value: "NA", label: "Namibie" },
    { value: "NP", label: "Népal" },
    { value: "NI", label: "Nicaragua" },
    { value: "NC", label: "Nouvelle-Calédonie" },
    { value: "NL", label: "Pays-Bas" },
    { value: "PE", label: "Pérou" },
    { value: "PH", label: "Philippines" },
    { value: "PL", label: "Pologne" },
    { value: "PF", label: "Polynésie française" },
    { value: "PT", label: "Portugal" },
    { value: "QA", label: "Qatar" },
    { value: "RO", label: "Roumanie" },
    { value: "GB", label: "Royaume-Uni" },
    { value: "RU", label: "Russie" },
    { value: "BL", label: "Saint-Barthélemy" },
    { value: "MF", label: "Saint-Martin" },
    { value: "PM", label: "Saint-Pierre-et-Miquelon" },
    { value: "RS", label: "Serbie" },
    { value: "SK", label: "Slovaquie" },
    { value: "SI", label: "Slovénie" },
    { value: "SE", label: "Suède" },
    { value: "CH", label: "Suisse" },
    { value: "TW", label: "Taïwan" },
    { value: "TH", label: "Thaïlande" },
    { value: "TN", label: "Tunisie" },
    { value: "TR", label: "Turquie" },
    { value: "UA", label: "Ukraine" },
    { value: "UY", label: "Uruguay" },
    { value: "VN", label: "Vietnam" },
    { value: "VE", label: "Venezuela" },
    { value: "WF", label: "Wallis-et-Futuna" },
    { value: "ZM", label: "Zambie" },
    { value: "ZW", label: "Zimbabwe" },
    { value: "INT", label: "International" }
  ];

   // Mémoriser categoryOptions
   const categoryOptions = useMemo(() => [
    { value: "Gaming", label: "Gaming" },
    { value: "Musique", label: "Musique" },
    { value: "Technologie", label: "Technologie" },
    { value: "Art", label: "Art" },
    { value: "Anime/Manga", label: "Anime/Manga" },
    { value: "Roleplay", label: "Roleplay" },
    { value: "Communauté", label: "Communauté" },
    { value: "Éducation", label: "Éducation" },
    { value: "Sports", label: "Sports" },
    { value: "Memes", label: "Memes" },
    { value: "Programmation", label: "Programmation" },
    { value: "Voyage", label: "Voyage" },
    { value: "Fitness", label: "Fitness" },
    { value: "Cinéma", label: "Cinéma" },
    { value: "Livres", label: "Livres" },
    { value: "Science", label: "Science" },
    { value: "Support", label: "Support" },
    { value: "NSFW", label: "NSFW" },
    { value: "Streaming", label: "Streaming" },
    { value: "Crypto", label: "Crypto/Blockchain" },
    { value: "Cuisine", label: "Cuisine" },
    { value: "Photographie", label: "Photographie" },
    { value: "Esports", label: "Esports" },
    { value: "Animaux", label: "Animaux" },
    { value: "Développement Personnel", label: "Développement Personnel" },
    { value: "Mode", label: "Mode/Beauté" },
    { value: "Histoire", label: "Histoire" },
    { value: "Philosophie", label: "Philosophie" },
    { value: "Autre", label: "Autre" }
  ], []);

  // Mettre à jour les serveurs affichés en fonction des filtres et du tri
  useEffect(() => {
    let updatedServers = favoritesFilter ? favoriteServers : servers;

    // Filtrer par pays sélectionnés
    if (selectedCountries.length > 0) {
      const selectedCountryValues = selectedCountries.map((country) => country.value);
      updatedServers = updatedServers.filter((server) =>
        server.countries.some((country) => selectedCountryValues.includes(country))
      );
    }

    // Filtrer par catégories sélectionnées
    if (selectedCategories.length > 0) {
      const selectedCategoryValues = selectedCategories.map((category) => category.value);
      updatedServers = updatedServers.filter((server) =>
        server.categories.some((category) => selectedCategoryValues.includes(category))
      );
    }

    // Filtrer par terme de recherche
    if (searchTerm) {
      const lowercasedSearchTerm = searchTerm.toLowerCase();
      updatedServers = updatedServers.filter((server) => {
        const nameMatch = server.name.toLowerCase().includes(lowercasedSearchTerm);
        const tagsMatch =
          server.tags && server.tags.some((tag) => tag.toLowerCase().includes(lowercasedSearchTerm));
        return nameMatch || tagsMatch;
      });
    }

    // Appliquer le tri
    if (sortOption === 'random') {
      // Mélanger les serveurs de manière aléatoire
      updatedServers = [...updatedServers].sort(() => Math.random() - 0.5);
    } else {
      updatedServers = [...updatedServers].sort((a, b) => {
        let aValue = a[sortOption];
        let bValue = b[sortOption];

        // Gérer les valeurs nulles ou indéfinies
        if (aValue === undefined || aValue === null) aValue = 0;
        if (bValue === undefined || bValue === null) bValue = 0;

        // Convertir les chaînes en minuscules pour un tri insensible à la casse
        if (typeof aValue === 'string') aValue = aValue.toLowerCase();
        if (typeof bValue === 'string') bValue = bValue.toLowerCase();

        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setDisplayedServers(updatedServers);
  }, [
    servers,
    favoriteServers,
    favoritesFilter,
    selectedCountries,
    selectedCategories,
    searchTerm,
    sortOption,
    sortOrder,
  ]);

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCountryChange = (selectedOptions) => {
    setSelectedCountries(selectedOptions || []);
  };

  const handleFavoritesFilterToggle = () => {
    setFavoritesFilter(!favoritesFilter);
  };

  const handleAddServer = () => {
    navigate("/add-server");
  };

  // Gestion du défilement infini
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200 &&
      !isFetching
    ) {
      setIsFetching(true);
      setVisibleServersCount((prev) => prev + 8);
      setIsFetching(false);
    }
  }, [isFetching]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);


  return (
    <div className="home" ref={listInnerRef}>
      {/* Barre de recherche et filtres */}
      <div className="flex flex-col md:flex-row md:items-center justify-between mb-6 space-y-4 md:space-y-0">
        {/* Champ de recherche */}
        <input
          type="text"
          placeholder={t("search_placeholder")}
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 bg-gray-800 text-gray-200 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />

        {/* Sélecteur de pays */}
        <div className="w-full md:w-1/3">
          <Select
            isMulti
            options={countryOptions}
            value={selectedCountries}
            onChange={handleCountryChange}
            placeholder={t("select_country")}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: '#2D3748',
                borderColor: '#4A5568',
                color: '#FFFFFF',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: '#2D3748',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#4A5568' : '#2D3748',
                color: '#FFFFFF',
              }),
              multiValue: (provided) => ({
                ...provided,
                backgroundColor: '#4A5568',
              }),
              multiValueLabel: (provided) => ({
                ...provided,
                color: '#FFFFFF',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: '#9CA3AF',
              }),
              input: (provided) => ({
                ...provided,
                color: "white",
              }),
            }}
          />
        </div>

        {/* Sélecteur de catégories */}
        <div className="w-full md:w-1/3">
          <Select
            isMulti
            options={categoryOptions}
            value={selectedCategories}
            onChange={(selectedOptions) => setSelectedCategories(selectedOptions || [])}
            placeholder={t('filter_by_category')}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: '#2D3748',
                borderColor: '#4A5568',
                color: '#FFFFFF',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: '#2D3748',
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#4A5568' : '#2D3748',
                color: '#FFFFFF',
              }),
              multiValue: (provided) => ({
                ...provided,
                backgroundColor: '#4A5568',
              }),
              multiValueLabel: (provided) => ({
                ...provided,
                color: '#FFFFFF',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: '#9CA3AF',
              }),
              input: (provided) => ({
                ...provided,
                color: "white",
              }),
            }}
          />
        </div>

        {/* Options de tri */}
        <div className="flex items-center space-x-2">
          <select
            value={sortOption}
            onChange={handleSortChange}
            className="p-2 bg-gray-800 text-gray-200 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            <option value="averageRating">{t("sort_by_rating")}</option>
            <option value="memberCount">{t("sort_by_members")}</option>
            <option value="voiceMemberCount">{t("sort_by_voice")}</option>
            <option value="active7d">{t("sort_by_write")}</option>
            <option value="netChange">{t("sort_by_net_change")}</option>
            <option value="random">{t("sort_random")}</option>
          </select>
          {sortOption !== "random" && (
            <button
              onClick={toggleSortOrder}
              className="p-2 bg-gray-800 text-gray-200 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              {sortOrder === "asc" ? t("ascending") : t("descending")}
            </button>
          )}
          {/* Bouton pour filtrer les favoris */}
          {currentUser && (
            <button
              onClick={handleFavoritesFilterToggle}
              className="favorites-button focus:outline-none"
              title={favoritesFilter ? t("show_all_servers") : t("show_favorites")}
            >
              {favoritesFilter ? (
                <FaHeart className="text-pink-500 text-2xl" />
              ) : (
                <FaRegHeart className="text-gray-300 text-2xl" />
              )}
            </button>
          )}
        </div>
      </div>

      {/* Bouton pour ajouter un serveur */}
      {currentUser && (
        <div className="text-center mb-8">
          <button
            onClick={handleAddServer}
            className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded"
          >
            {t("add_server")}
          </button>
        </div>
      )}

      {/* Affichage des serveurs */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 auto-rows-fr">
        {displayedServers.slice(0, visibleServersCount).map((server) => (
          <ServerCard
            key={server.id}
            server={server}
            isFavorite={favoriteServers.some((favServer) => favServer.id === server.id)}
            refreshFavorites={refetchFavorites}
            invalidateServers={() => queryClient.invalidateQueries(["servers"])}
          />
        ))}
      </div>

      {/* Animation de chargement pour le défilement infini */}
      {isFetching && (
        <div className="flex justify-center items-center my-8">
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

export default Home;
