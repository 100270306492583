import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Mettez à jour l'état pour afficher l'UI de secours
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Vous pouvez également enregistrer l'erreur dans un service de rapport
    console.error("Erreur capturée par ErrorBoundary :", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Vous pouvez afficher n'importe quelle UI de secours
      return (
        <div className="p-6">
          <h1 className="text-3xl font-bold mb-4">
            Quelque chose s'est mal passé.
          </h1>
          <p className="text-base">
            Nous sommes désolés pour ce désagrément. Veuillez réessayer plus tard.
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
