import React from "react";
import { useNavigate } from "react-router-dom";

function AdminLayout({ children }) {
  const navigate = useNavigate();

  return (
    <div className="flex">
      <div className="w-60 bg-gray-800 text-white border-r border-gray-700 min-h-screen">
        <nav>
          <ul>
            <li>
              <button
                onClick={() => navigate("/admin/moderators-management")}
                className="w-full text-left px-4 py-2 hover:bg-gray-700"
              >
                Gestion des Modérateurs
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/admin/servers-list")}
                className="w-full text-left px-4 py-2 hover:bg-gray-700"
              >
                Liste des Serveurs
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/admin/users-list")}
                className="w-full text-left px-4 py-2 hover:bg-gray-700"
              >
                Liste des Utilisateurs
              </button>
            </li>
            <li>
              <button
                onClick={() => navigate("/admin/logs")}
                className="w-full text-left px-4 py-2 hover:bg-gray-700"
              >
                Logs d'Activité
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <main className="flex-grow p-6">{children}</main>
    </div>
  );
}

export default AdminLayout;
