import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-300 py-3 px-2 mt-auto text-center">
      <p className="text-sm">
        Besoin d'aide ? Rejoignez notre{" "}
        <a
          href="https://discord.gg/votre_lien_de_support"
          className="underline hover:text-gray-100"
        >
          serveur Discord de support
        </a>
        .
      </p>
      <p className="text-xs mt-1">
        © {new Date().getFullYear()} DiscordPlace. Tous droits réservés.
      </p>
      <p className="text-xs mt-1">
        <Link
          to="/privacy-policy"
          className="underline hover:text-gray-100"
        >
          Politique de Confidentialité
        </Link>{" "}
        |{" "}
        <Link
          to="/terms-of-service"
          className="underline hover:text-gray-100"
        >
          Termes de Service
        </Link>
      </p>
    </footer>
  );
}

export default Footer;
