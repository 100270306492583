import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api";

function UsersList() {
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery(["allUsers"], () =>
    api.get("/admin/users").then((res) => res.data)
  );

  const deleteUserMutation = useMutation(
    (userId) => api.delete(`/admin/users/${userId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["allUsers"]);
      },
      onError: (error) => {
        console.error(
          "Erreur lors de la suppression de l'utilisateur :",
          error
        );
        alert("Erreur lors de la suppression de l'utilisateur.");
      },
    }
  );

  const handleDeleteUser = (userId, isAdmin) => {
    if (isAdmin) {
      alert("Vous ne pouvez pas supprimer un administrateur.");
      return;
    }
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cet utilisateur ?")
    ) {
      deleteUserMutation.mutate(userId);
    }
  };

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );

  if (error)
    return (
      <p className="text-red-500">
        Erreur lors du chargement des utilisateurs.
      </p>
    );

  return (
    <div className="overflow-x-auto mt-4">
      <table className="min-w-full bg-gray-800 text-white">
        <thead>
          <tr>
            <th className="py-2 px-4">Avatar</th>
            <th className="py-2 px-4">ID</th>
            <th className="py-2 px-4">Nom d'utilisateur</th>
            <th className="py-2 px-4">Discriminateur</th>
            <th className="py-2 px-4">Email</th>
            <th className="py-2 px-4">Rôle</th>
            <th className="py-2 px-4">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user) => (
            <tr key={user.id} className="border-t border-gray-700">
              <td className="py-2 px-4">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    user.avatar
                      ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
                      : `https://cdn.discordapp.com/embed/avatars/${
                          user.discriminator % 5
                        }.png`
                  }
                  alt={user.username}
                />
              </td>
              <td className="py-2 px-4">{user.id}</td>
              <td className="py-2 px-4">{user.username}</td>
              <td className="py-2 px-4">{user.discriminator}</td>
              <td className="py-2 px-4">{user.email || "N/A"}</td>
              <td className="py-2 px-4">
                {user.isAdmin
                  ? "Administrateur"
                  : user.isModerator
                  ? "Modérateur"
                  : "Utilisateur"}
              </td>
              <td className="py-2 px-4">
                <button
                  className="bg-red-600 hover:bg-red-700 text-white font-semibold py-1 px-2 rounded disabled:opacity-50"
                  onClick={() => handleDeleteUser(user.id, user.isAdmin)}
                  disabled={user.isAdmin}
                >
                  Supprimer
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UsersList;
