import React from 'react';

function TermsOfService() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Termes de Service</h1>

      <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
      <p className="mb-4">
        Bienvenue sur Place (ci-après dénommée "l'Application"), une application
        développée par cl0uz_ (ci-après dénommé "nous", "notre" ou "nos"). En
        accédant ou en utilisant l'Application, vous acceptez d'être lié par ces
        Termes de Service.
      </p>

      <h2 className="text-2xl font-semibold mb-2">2. Utilisation de l'Application</h2>
      <p className="mb-4">
        <strong>Admissibilité :</strong> Vous devez avoir au moins 13 ans pour
        utiliser l'Application.
      </p>
      <p className="mb-4">
        <strong>Conduite de l'utilisateur :</strong> Vous vous engagez à ne pas
        utiliser l'Application à des fins illégales ou non autorisées.
      </p>

      <h2 className="text-2xl font-semibold mb-2">3. Propriété Intellectuelle</h2>
      <p className="mb-4">
        Tous les contenus et matériaux disponibles sur l'Application sont protégés
        par les lois sur la propriété intellectuelle. Vous ne pouvez pas
        reproduire, distribuer ou créer des œuvres dérivées sans notre autorisation
        écrite préalable.
      </p>

      <h2 className="text-2xl font-semibold mb-2">4. Limitation de Responsabilité</h2>
      <p className="mb-4">
        L'Application est fournie "en l'état" sans aucune garantie, expresse ou
        implicite. Nous ne serons pas responsables des dommages directs,
        indirects, accessoires ou consécutifs résultant de l'utilisation ou de
        l'impossibilité d'utiliser l'Application.
      </p>

      <h2 className="text-2xl font-semibold mb-2">5. Modifications des Termes</h2>
      <p className="mb-4">
        Nous nous réservons le droit de modifier ces Termes de Service à tout
        moment. Les modifications prendront effet dès leur publication sur notre
        site web ou via l'Application.
      </p>

      <h2 className="text-2xl font-semibold mb-2">6. Résiliation</h2>
      <p className="mb-4">
        Nous pouvons suspendre ou résilier votre accès à l'Application à tout
        moment, sans préavis, pour toute violation de ces Termes.
      </p>

      <h2 className="text-2xl font-semibold mb-2">7. Loi Applicable</h2>
      <p className="mb-4">
        Ces Termes de Service seront régis et interprétés conformément aux lois en
        vigueur en France.
      </p>

      <h2 className="text-2xl font-semibold mb-2">8. Contact</h2>
      <p>
        Pour toute question concernant ces Termes de Service, veuillez nous
        contacter à :{' '}
        <a href="mailto:paulblanchaard@gmail.com" className="text-blue-500 underline">
          paulblanchaard@gmail.com
        </a>.
      </p>
    </div>
  );
}

export default TermsOfService; 